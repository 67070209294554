<template>
    <div id="tabStaffSale">
        <el-alert title="时间维度逻辑：报名缴费时间 (只计入全款订单)" type="info" :closable="false" show-icon />
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item">
                    <span class="condition_label">项目</span>
                    <span class="condition_content">
                        <ProjectSelect @change="onProjectNoChange" />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">成单日期</span>
                    <span class="condition_content">
                        <el-date-picker
                            v-model="date"
                            type="date"
                            @change="initData"
                            :clearable="false"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">运营</span>
                    <span class="condition_content">
                        <StaffSelect :clearable="true" @change="onStaffChange" />
                    </span>
                </div>
            </div>
            <div class="opt_container"></div>
        </div>
        <el-table
            class="staff_data report-table-2"
            height="calc(100% - 40px)"
            :data="tableData"
            border
            v-if="!loading"
            row-key="index"
            lazy
            :load="onTableLoad"
        >
            <el-table-column label="投放大类 > 投放渠道" align="center">
                <template slot-scope="scope">
                    <div class="provider">
                        <div>{{ scope.row.title }}</div>
                        <div class="item">
                            <span>单量</span>
                            <span>流水</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="right">
                <template slot="header">
                    <span>{{ tableHeader.month }}</span>
                </template>
                <template slot-scope="scope">
                    <div class="item">
                        <span @click="onShowTradeDetailDrawer({ ...scope.row, type: 'month' })" class="count">
                            {{ scope.row.monthCnt }}
                        </span>
                        <NumFormat :num="scope.row.monthFee / 100" />
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="right">
                <template slot="header">
                    <span>{{ tableHeader.week }}</span>
                </template>
                <template slot-scope="scope">
                    <div class="item">
                        <span @click="onShowTradeDetailDrawer({ ...scope.row, type: 'week' })" class="count">
                            {{ scope.row.weekCnt }}
                        </span>
                        <NumFormat :num="scope.row.weekFee / 100" />
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="当日成单" align="right">
                <template slot="header">
                    <span>{{ tableHeader.day }}</span>
                </template>
                <template slot-scope="scope">
                    <div class="item">
                        <span @click="onShowTradeDetailDrawer({ ...scope.row, type: 'today' })" class="count">
                            {{ scope.row.todayCnt }}
                        </span>
                        <NumFormat :num="scope.row.todayFee / 100" />
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <TradeDetailDrawer
            :projectNo="projectNo"
            :tradeDetail="activeTradeDetail"
            :providerMap="providerMap"
            :staffId="staffId"
            :isShow="isShowTradeDetailDrawer"
            @onClose="onCloseTradeDetailDrawer"
        />
    </div>
</template>
<script>
import { getProviderCategoryData } from '@/js/api/advertApi.js'
import { getOverviewTrade } from '@/js/api/reportApi.js'
import { scopeMap } from '@/js/config/reportForm.js'
import dayjs from 'dayjs'
import ProjectSelect from '@/views/components/ProjectSelect'
import StaffSelect from '@/views/components/StaffSelect'
import TradeDetailDrawer from './TradeDetailDrawer.vue'

export default {
    data() {
        return {
            providerMap: null,
            projectNo: '',
            staffId: '',
            date: dayjs().format('YYYY-MM-DD'),
            tableData: [],
            tableHeader: {
                provider: '渠道',
                month: '',
                week: '',
                day: '',
            },
            loading: true,

            isShowTradeDetailDrawer: false,
            activeTradeDetail: null,
        }
    },
    components: { TradeDetailDrawer, StaffSelect, ProjectSelect },
    methods: {
        onProjectNoChange(val) {
            this.projectNo = val
            this.getProviderCategoryDataApi()
        },
        onShowTradeDetailDrawer(item) {
            this.isShowTradeDetailDrawer = true
            this.activeTradeDetail = item
        },
        onCloseTradeDetailDrawer() {
            this.isShowTradeDetailDrawer = false
            this.activeTradeDetail = null
        },
        onStaffChange(val) {
            this.staffId = val
            this.initData()
        },
        async initData() {
            this.loading = true
            const { date, staffId } = this
            const all = await getOverviewTrade({ projectNo: this.projectNo, date, staffId, groupBy: 'all' })
            const scope = await getOverviewTrade({ projectNo: this.projectNo, date, staffId, groupBy: 'scope' })
            const tableData = []
            all.data.forEach((item) => {
                tableData.push(this.setTableItem({ ...item, title: '总计' }))
                this.setTableHeader(item)
            })
            scope.data.forEach((item) => {
                tableData.push(
                    this.setTableItem({
                        ...item,
                        title: scopeMap[item.scope],
                        hasChildren: true,
                    })
                )
            })
            this.tableData = tableData
            this.loading = false
        },
        onTableLoad(tree, treeNode, resolve) {
            const { date, staffId } = this
            if (tree.scope && !tree.categoryNo) {
                getOverviewTrade({ date, staffId, groupBy: 'categoryNo', scope: tree.scope }).then((res) => {
                    const itemList = []
                    res.data.forEach((item) => {
                        itemList.push(
                            this.setTableItem({
                                ...item,
                                title: this.categoryMap[item.categoryNo]['name'],
                                categorySeq: this.categoryMap[item.categoryNo]['seq'],
                                scope: tree.scope,
                                hasChildren: true,
                            })
                        )
                    })
                    res.data.sort((a, b) => a.categorySeq - b.categorySeq)
                    resolve(itemList)
                })
            } else if (tree.scope && tree.categoryNo) {
                getOverviewTrade({
                    date,
                    staffId,
                    groupBy: 'providerNo',
                    scope: tree.scope,
                    categoryNo: tree.categoryNo,
                }).then((res) => {
                    const itemList = []
                    res.data.forEach((item) => {
                        itemList.push(
                            this.setTableItem({
                                ...item,
                                title: this.providerMap[item.providerNo],
                                scope: tree.scope,
                                categoryNo: tree.categoryNo,
                                hasChildren: false,
                            })
                        )
                    })
                    resolve(itemList)
                })
            }
        },

        setTableItem(item) {
            return { ...item }
        },
        setTableHeader(item) {
            this.tableHeader.month = `本月成单[${this.getDate(item.monthStart)}-${this.getDate(item.monthEnd)}]`
            this.tableHeader.week = `本周成单[${this.getDate(item.weekStart)}-${this.getDate(item.weekEnd)}]`
            this.tableHeader.day = `当日成单[${this.getDate(item.todayStart)}]`
        },
        getDate(str) {
            const date = str.substr(5, 5)
            const list = date.split('-')
            return `${list[0]}月${list[1]}号`
        },

        getProviderCategoryDataApi() {
            getProviderCategoryData().then((res) => {
                this.providerMap = res.providerMap
                this.categoryMap = res.categoryMap
                this.initData()
            })
        },
    },
}
</script>
<style lang="less" scoped>
#tabStaffSale {
    height: 100%;
    display: flex;
    flex-direction: column;
    .query_header {
        flex-shrink: 0;
    }
}
/deep/ .staff_data {
    flex-grow: 1;
    .el-table__expand-icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
    .el-table__expand-icon--expanded {
        transform: rotate(90deg) translateX(-50%);
    }
    .el-table__placeholder,
    .el-table__indent {
        display: none;
    }
    td,
    .cell {
        padding: 0 !important;
    }
    .provider {
        display: flex;
        > div {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            span {
                width: 100%;
                text-align: center;
                padding: 4px 0;
            }
            > span:first-child {
                border-bottom: 1px solid #ebeef5;
            }
        }
        > div:first-child {
            border-right: 1px solid #ebeef5;
        }
    }
    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span {
            width: 100%;
            padding: 4px 12px;
            box-sizing: border-box;
        }
        .count {
            cursor: pointer;
            text-decoration: underline;
        }
        > span:first-child {
            border-bottom: 1px solid #ebeef5;
        }
    }
}
</style>
