<template>
    <div class="page_container">
        <el-tabs v-model="activeName">
            <el-tab-pane label="成单报表" name="staffSale">
                <TabStaffSale v-if="activeName === 'staffSale'" />
            </el-tab-pane>
            <el-tab-pane label="月度流水排行" name="tabRanking">
                <TabRanking v-if="activeName === 'tabRanking'" />
            </el-tab-pane>
        </el-tabs>
        <Watermark />
    </div>
</template>

<script>
import TabStaffSale from './components/sale/TabStaffSale.vue'
import TabRanking from './components/sale/TabRanking.vue'

export default {
    data() {
        return {
            activeName: 'staffSale',
        }
    },
    components: { TabStaffSale, TabRanking },
}
</script>
<style lang="less" scoped>
/deep/ .el-tabs {
    height: 100%;
    .el-tabs__content {
        height: calc(100% - 55px);
    }
    .el-tab-pane {
        height: 100%;
    }
}
</style>
