<template>
    <el-drawer
        :visible.sync="isShow"
        :before-close="onClose"
        title="机会详情"
        :append-to-body="true"
        size="780px"
        show-close
    >
        <div>
            <ExportFile host="hunter" path="/oppo/tradeDetailExport" @before-export="onBeforeExport" />
        </div>
        <br />
        <el-table :data="tableData" stripe border>
            <el-table-column width="60" prop="oppoId" label="机会ID" />
            <el-table-column width="80" label="投放渠道">
                <template slot-scope="scope">
                    <div>{{ providerMap[scope.row.providerNo] || '无渠道' }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="productTitle" label="商品"></el-table-column>
            <el-table-column prop="paidFeeText" label="成交金额">
                <template slot-scope="scope">
                    <NumFormat :num="scope.row.paidFee / 100" />
                </template>
            </el-table-column>
            <el-table-column prop="paidTime" label="成交时间"></el-table-column>
            <el-table-column prop="staffId" label="运营">
                <template slot-scope="scope">
                    <StaffName :staff="scope.row.staff" />
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            background
            @size-change="(val) => onPaginationChange('size', val)"
            @current-change="(val) => onPaginationChange('page', val)"
            :current-page.sync="page"
            :page-size="size"
            :page-sizes="[10, 20, 50, 100]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        />
    </el-drawer>
</template>
<script>
import { getStaffList } from '@/js/api/staffApi'
import { oppoTradeDetail } from '@/js/api/saleApi.js'
import { filterQueryEmpty } from '@/js/utils.js'

export default {
    props: ['isShow', 'tradeDetail', 'staffId', 'providerMap', 'projectNo'],
    data() {
        return {
            tableData: [],
            page: 1,
            size: 10,
            total: 0,
        }
    },
    watch: {
        isShow: function (isShow) {
            if (isShow) {
                this.tableData = []
                this.page = 1
                this.size = 10
                this.total = 0
                this.oppoTradeDetailApi()
            }
        },
    },
    methods: {
        onClose() {
            this.tableData = []
            this.$emit('onClose')
        },
        onBeforeExport(cb) {
            let params = {
                projectNo: this.projectNo,
                staffId: this.staffId,
                startTime: this.tradeDetail[`${this.tradeDetail.type}Start`],
                endTime: this.tradeDetail[`${this.tradeDetail.type}End`],
            }
            if (this.tradeDetail.scope) {
                params.scope = this.tradeDetail.scope
            }
            if (this.tradeDetail.categoryNo) {
                params.categoryNo = this.tradeDetail.categoryNo
            }
            if (this.tradeDetail.providerNo) {
                params.providerNo = this.tradeDetail.providerNo
            }
            params = filterQueryEmpty(params)
            cb(params)
        },
        onPaginationChange(type, value) {
            if (type === 'size') {
                this.size = value
                this.page = 1
            } else {
                this.page = value
            }
            this.$nextTick(this.oppoTradeDetailApi)
        },
        async oppoTradeDetailApi() {
            const { page, size } = this
            let params = {
                page,
                size,
                projectNo: this.projectNo,
                showPage: true,
                staffId: this.staffId,
                startTime: this.tradeDetail[`${this.tradeDetail.type}Start`],
                endTime: this.tradeDetail[`${this.tradeDetail.type}End`],
            }
            if (this.tradeDetail.scope) {
                params.scope = this.tradeDetail.scope
            }
            if (this.tradeDetail.categoryNo) {
                params.categoryNo = this.tradeDetail.categoryNo
            }
            if (this.tradeDetail.providerNo) {
                params.providerNo = this.tradeDetail.providerNo
            }
            params = filterQueryEmpty(params)

            const res1 = await oppoTradeDetail(params)
            if (res1.data.list.length !== 0) {
                const _staffIds = []
                res1.data.list.forEach((i) => {
                    if (i.staffId && !_staffIds.includes(i.staffId)) {
                        _staffIds.push(i.staffId)
                    }
                })
                const res2 = await getStaffList({ staffIds: _staffIds })
                const _map = {}
                res2.data.forEach((staff) => {
                    _map[staff.id] = staff
                })
                res1.data.list.forEach((i) => {
                    i.staff = _map[i.staffId] || {}
                })
            }
            this.tableData = res1.data.list
            this.total = res1.data.total
            this.page = res1.data.pageNum
        },
    },
}
</script>
