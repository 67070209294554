<template>
    <div id="tabRanking">
        <el-alert title="时间维度逻辑：报名缴费时间" type="info" :closable="false" show-icon />
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item">
                    <span class="condition_label">项目</span>
                    <span class="condition_content">
                        <ProjectSelect @change="onProjectNoChange" />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_content">
                        <el-date-picker
                            v-model="date"
                            :picker-options="pickerOptions"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            :clearable="false"
                            @change="getTradeRankApi"
                            size="mini"
                        />
                    </span>
                </div>
            </div>
        </div>
        <div class="ranking_container" :key="uuid">
            <div class="ranking_item" v-for="(item, index) in rankingData" :key="index">
                <div class="border title">{{ item.name }}</div>
                <div class="total_item border staff_item">
                    <div class="label">总计</div>
                    <div class="content">
                        <NumFormat :num="item.totalFeeSum / 100" :float="0" />
                        <span>({{ item.totalStaff }})</span>
                    </div>
                </div>
                <template v-for="indexNum in maxLength">
                    <div class="border staff_item" v-if="item.children[indexNum - 1]" :key="indexNum">
                        <div class="label" :class="item.children[indexNum - 1]['state'] !== 0 ? 'quit_staff' : ''">
                            {{ item.children[indexNum - 1]['name'] }}
                        </div>
                        <div class="content">
                            <NumFormat :num="item.children[indexNum - 1]['feeSum'] / 100" :float="0" />
                        </div>
                    </div>
                    <div class="border staff_item" v-else :key="indexNum">-</div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import { getStaffGroupList } from '@/js/api/staffApi.js'
import { getTradeRank } from '@/js/api/reportApi.js'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import ProjectSelect from '@/views/components/ProjectSelect'

export default {
    components: { ProjectSelect },
    data() {
        return {
            projectNo: '',
            pickerOptions: {
                shortcuts: [
                    {
                        text: '本月',
                        onClick(picker) {
                            picker.$emit('pick', [dayjs().startOf('month').toDate(), new Date()])
                        },
                    },
                    {
                        text: '上个月',
                        onClick(picker) {
                            let start = dayjs().subtract(1, 'month')
                            picker.$emit('pick', [start.startOf('month').toDate(), start.endOf('month').toDate()])
                        },
                    },
                ],
            },
            maxLength: 0,
            rankingData: [],
            date: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
            uuid: '',
        }
    },
    methods: {
        onProjectNoChange(val) {
            this.projectNo = val
            this.groupList = []
            this.getTradeRankApi()
        },
        async getTradeRankApi() {
            if (this.groupList.length === 0) {
                const group = await getStaffGroupList({})
                this.groupList = group.data
            }
            const groupMap = { 0: { id: 0, name: '未分组', seq: 999, children: [] } }
            this.groupList.forEach((item) => {
                item.children = []
                groupMap[item.id] = item
            })
            getTradeRank({ projectNo: this.projectNo, tradeTimes: this.date }).then((res) => {
                res.data.forEach((item) => {
                    if (groupMap[item.groupId]) {
                        groupMap[item.groupId].children.push(item)
                    } else {
                        if (item.feeSum > 100) {
                            groupMap['0'].children.push(item)
                        }
                    }
                })

                // 过滤无数据的组
                let maxLength = 0
                const rankingData = []
                Object.keys(groupMap).forEach((key) => {
                    const staffNum = groupMap[key].children.length
                    if (maxLength < staffNum) {
                        maxLength = staffNum
                    }

                    if (staffNum !== 0) {
                        groupMap[key].children.sort((a, b) => b.feeSum - a.feeSum)
                        rankingData.push(groupMap[key])
                    }
                })
                rankingData.sort((a, b) => a.seq - b.seq)
                // 计算总计
                rankingData.forEach((item) => {
                    item.totalFeeSum = 0
                    item.totalStaff = 0
                    item.children.forEach((staff) => {
                        item.totalFeeSum += staff.feeSum || 0
                        if (staff.feeSum > 50 * 100 || staff.state === 0) {
                            item.totalStaff += 1
                        }
                    })
                })
                this.maxLength = maxLength
                this.rankingData = rankingData
                this.uuid = uuidv4()
            })
        },
    },
}
</script>
<style lang="less" scoped>
#tabRanking {
    height: 100%;
}
.opt_container {
    padding: 0;
}
.ranking_container {
    height: calc(100% - 90px);
    overflow-y: auto;
    display: flex;
    &::-webkit-scrollbar {
        width: 6px;
        height: 8px;
    }
}
.ranking_item {
    width: 220px;
    min-width: 180px;
    text-align: center;
    &:first-child {
        .border {
            border-left: 1px solid #ebeef5;
        }
    }
    .title {
        line-height: 3em;
        font-size: 15px;
    }
    .border {
        border-top: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
    }
    .staff_item {
        display: flex;
        height: 42px;
        align-items: center;
        justify-content: center;
        > div {
            font-size: 13px;
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .label {
            border-right: 1px solid #ebeef5;
        }
        .content {
            justify-content: flex-end;
            padding: 0 12px;
            box-sizing: border-box;
        }
        &:last-child {
            border-bottom: 1px solid #ebeef5;
        }
    }
}

.total_item {
    font-weight: 700;
    color: #409eff;
}
.quit_staff {
    color: #f00;
}
</style>
